function getBaseUrl() {
    return (window.location.hostname === "localhost") ? '' : '/fragment/gamefilter';
}

/**
 * Loads the next page of games and appends it to the target element
 *
 * @param {HTMLButtonElement} buttonElement
 * @param {HTMLElement} targetElement
 * @param {string} path
 * @param {number} max
 * @param {number} size
 */
export async function nextPage(buttonElement, targetElement, path, max, size) {
    // Return if the button element is disabled, such as if a previous request is still being processed
    if (buttonElement.disabled) return;

    // Get the authorization meta-element which contains the user's authorization token
    // This should be done on the parent window, in order to support this window being rendered through an iframe
    const meta = parent.document.querySelector('meta[http-equiv=Authorization]');

    // Return if the authorization meta-element is absent or empty
    if (meta == null || meta.content == null || meta.content.length === 0) return;

    let count = targetElement.childElementCount;

    try {
        // Disable the button element until the request has been completed
        buttonElement.setAttribute('disabled', '');
        buttonElement.setAttribute('loading', '');

        const response = await fetch(`${getBaseUrl()}/ajax/${path}&size=${size}&offset=${count}`, {
            headers: { Authorization: `Bearer ${meta.content}` }
        });

        if (response.ok) {
            targetElement.insertAdjacentHTML("beforeend", await response.text());

            count = targetElement.childElementCount;
            if (response.status === 204 || (max > 0 && count >= max)) {
                buttonElement.parentElement.classList.add("gff-game-pagination-button--hidden");
            }
        }
    } finally {
        // Enable the button element when the request has been completed
        buttonElement.removeAttribute('disabled');
        buttonElement.removeAttribute('loading')
    }
}

window.GamePagination = {
    nextPage
}
